import React from "react"
import { Link } from "gatsby"
import shape from "../../assets/images/shape/vector-shape6.png"
import starIcon from "../../assets/images/star-icon.png"
import { StaticImage } from "gatsby-plugin-image"

const Training = () => {
  return (
    <div className="courses-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="icon" /> Train with us
          </span>
          <h2>Certification & Training</h2>
          <p>
            
          </p>
        </div>

        <div className="row">
          <div id="ea" className="col-lg-6 col-md-6">
            <div className="single-courses-box ">
              <div className="courses-image">
                <Link to="/course-details" className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/executive_assistant.jpg"
                    placeholder="blurred"
                    alt="Executive Assistant"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/course-details">Executive Assistant</Link>
                </h3>
                <p>
                Executive PA roles are wide open for confident candidates with outgoing personalities and relentless attention to detail. The professional PA is the ultimate multitasker, hired to oversee the personal affairs and activities of those they support. Some secretaries and PAs handle everyday admin for established organisations, while others are hired by a single executive, entrepreneur, or influential figure. 
                Whichever path you choose, to become a trusted and respected PA or Secretary is to open the door to a world of incredible career opportunities. 
                Success as a PA or secretary is about personal qualities and character as it is hard skills. If you consider yourself a responsible, hardworking people person with excellent time management skills, you’re already halfway there. 
                It’s simply a case of getting to know the functions of PAs and secretarial workers to ready yourself for a fascinating career in the field.
                Learn how to handle an extensive range of essential office admin duties, compose important business letters and handle complex mail and shipping requirements. Course content also covers making travel arrangements on behalf of others, organising meetings and events, the importance of time management as a PA, and how to become a better communicator.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="stress" className="col-lg-6 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
              <Link to="/course-details" className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/stress.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Stress management"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/course-details">
                    Stress management
                  </Link>
                </h3>
                <p>
                  Learn how to manage stress at work. A comprehensive stress management training course offering practical solutions to reduce and manage workplace stress.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="interview" className="col-lg-6 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/course-details">
                    Interview skills
                  </Link>
                </h3>
                <p>
                  Learn how to succeed at interviews and get the necessary tools to land your dream job in this online course.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="communication" className="col-lg-6 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
              <Link to="/course-details" className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/communication.jpg"
                    placeholder="blurred"
                    alt="Feng Shui"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/course-details">
                    Communication skills
                  </Link>
                </h3>
                <p>
                Enhance Your Ability to Influence Others.
                In this course, you'll discover the best communication strategies for effective workplace collaboration and reflect your communication style. You'll learn the importance of matching your communication style with your work and project goals and learn how to manage and control challenging conversations.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="vector-shape6">
        <img src={shape} alt="about" />
      </div>
    </div>
  )
}

export default Training
