import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const CGAcademyBannerImage = () => {
    
  return (
    <section className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <StaticImage
            src="../../assets/images/cg-academy/cg-academy-image.jpg"
            placeholder="blurred"
            alt="CG Academy"
          />
        </div>
      </div>
    </section>
  )
}

export default CGAcademyBannerImage
