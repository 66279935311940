import React from 'react'
import Layout from "../components/App/Layout"
import CoursesContent from '../components/CGAcademy/CoursesContent'
import Training from '../components/CGAcademy/Training'
import PageBannerBackground from '../components/Common/PageBannerBackground'
import CGAcademyBannerImage from '../components/CGAcademy/CGAcademyBannerImage'

const CGAcademy = () => {
    return (
        <Layout page="Wellbeing">
            <PageBannerBackground
                pageTitle="WELLBEING"
                crumbs={[{page: "Home", url: "/"}, {page: "Courses"}]}  
                bgFileName="cg-academy/cg-academy-banner.jpg" 
            />
            <CoursesContent />
            <Training />
            <CGAcademyBannerImage />
        </Layout>
    );
}

export default CGAcademy;