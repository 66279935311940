import React from "react"
import {useStaticQuery, graphql, Link} from 'gatsby'
import shape from "../../assets/images/shape/vector-shape6.png"
import starIcon from "../../assets/images/star-icon.png"
import VideoResponsivePlayer from "../App/VideoResponsivePlayer"
import { StaticImage } from "gatsby-plugin-image"

const CoursesContent = () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)

  return (
    <div className="courses-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="icon" /> Learn with us
          </span>
          <h2>Life-changing Courses</h2>
          <p>
            The path to personal growth ALWAYS involves learning. So, if you
            want to improve any aspect of your life, an excellent place to start is
            with personal growth and self-development courses. <br />
            You don’t need any formal qualifications to study with us, just
            commitment, passion, and desire to find out what you are capable of.
            <br />
            You're never on your own, with ongoing tutor support and 24/7 access
            to our online community. We are stronger together, always connected!
          </p>
        </div>

        <div className="row">
          <div id="hypnobirthing" className="col-lg-4 col-md-6">
            <div className="single-courses-box ">
              <div className="courses-image"></div> 
              <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/hypnobirthing.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                    ratio={0.55}
                  />
                </Link>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }} >Hypnobirthing</Link>
                </h3>
                <p>
                  Put simply, our hypnobirthing and antenatal classes will give
                  you all the tools you need to have a positive birth and
                  navigate any choices you need to make with confidence. From
                  mental preparation to techniques that will help you give birth
                  more comfortably and efficiently. Whether you’re planning a
                  drug-free birth or a Caesarean, we are confident we can help
                  you.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="eft" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/eft.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>
                    EFT - Emotional Freedom Technique
                  </Link>
                </h3>
                <p>
                  The emotional freedom technique (EFT) is an alternative treatment
                  for physical pain and emotional distress. It's also referred
                  to as tapping or psychological acupressure. Tapping the body
                  can create a balance in your energy system and treat pain. It
                  is a form of counselling intervention that draws on various
                  theories of alternative medicine – including acupuncture,
                  neurolinguistic programming, energy medicine, and Thought
                  Field Therapy.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div> 
          </div>

          <div id="reiki" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/reiki.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Reiki</Link>
                </h3>
                <p>
                  The Reiki course is ideal for individuals who are interested
                  in learning more about becoming a Reiki healer. Reiki is an
                  incredible discipline that could cleanse your body and further
                  your spiritual understanding of yourself and the world around
                  you. Perhaps it could help others physically and emotionally.
                  Or maybe you can finally be healed and overcome chronic
                  problems that have worried you at times. You may be able to
                  quickly and easily use the healing energy of the
                  universe to overcome ANY obstacle once and for all. How would that make you feel? The possibility to be free of pain, the opportunity to remove those stressful burdens, the chance to see people's lives change before your eyes, thanks to your healing energy!
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="fengshui" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/cg-feng-shui.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Feng Shui"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>
                    Feng Shui (Home and Business)
                  </Link>
                </h3>
                <p>
                We empower students to get the best results for themselves, their families, and of course, their clients, teaching advanced feng shui techniques that can be applied to any residential or commercial environment.
                 We provide masterful feng shui
                  knowledge and instil you with the confidence to start your
                  practice. Aimed at absolute beginners and practising
                  consultants alike, the Professional Feng Shui Consultant
                  course guides you through every aspect of Feng Shui. Learn how
                  to analyse and apply professional feng shui techniques to many
                  environments.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="aromatherapy" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/aromatherapy.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Aromatherapy"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Aromatherapy</Link>
                </h3>
                <p>
                Aromatherapy is a natural therapy that uses the aromas and plant extracts of plants, herbs, and flowers. Train yourself in the art of Aromatherapy with this Aromatherapy Course. Learn the use of Essential Oils to improve physical and spiritual health. During the Aromatherapy course, learners will learn to apply Aromatherapy at a physical systems level and a spiritual level. Learn to use essential oils in your health and well-being aspect in a professional, safe and effective way.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="spiritual" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/spiritual-coaching.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Spiritual coaching</Link>
                </h3>
                <p>
                  A spiritual coach, also called a spiritual life coach,
                  explores the deeper connections between people and the
                  universe. They help others gain a new or deeper understanding
                  of the world they live in and the energies that flow within
                  it. Through their work, a spiritual coach will use various
                  healing tools to support their clients’ journeys. They act as
                  a guide to unleashing self-trust and compassion in another
                  person.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="crystal" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/crystal_healing.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Crystal Healing"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Crystal healing</Link>
                </h3>
                <p>
                  What if you could quickly strengthen your immune system,
                  prevent health problems, and experience glowing vitality, all
                  by knowing how to use the hidden energy within crystals?
                  Crystal healing provides the simple and practical action steps
                  to help you reach the potential of physical health that waits
                  inside you. This fantastic course teaches how to work with the
                  three parts that are "you" (mind-body-spirit.) And when you
                  balance these three, you may realise a harmonising of your
                  very essence using Crystal healing energy.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="herbs" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
            <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/herbs.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Herbs</Link>
                </h3>
                <p>
                  You may be able to enjoy the foods you love and improve your
                  health using herbs in the way they were intended. This course
                  shows you how!
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="hypnotherapist" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
              <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/hypnotherapy.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Aromatherapy"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Hypnotherapist</Link>
                </h3>
                <p>
                  Hypnotherapists use hypnotism to help clients deal with
                  trauma, break addictions or achieve other positive changes in
                  their lives. Our hypnotherapy course is a practitioner
                  diploma, and it is packed full of information and techniques.
                  It is a very practical course. Hypnotherapy is a practical
                  therapy.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="neurolinguistic" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/nlp.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Neurolinguistic</Link>
                </h3>
                <p>
                  The Neurolinguistic Programming (NLP) Skills training course
                  covers the most valuable fundamentals of NLP for both personal
                  and business use. Theoretical information is provided throughout the NLP Diploma course, followed by exercises enabling the learner to practice and consolidate NLP skills.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="meditation" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/meditation.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Meditation</Link>
                </h3>
                <p>
                  The course covers the physical benefits such as decreasing
                  muscle tension and reducing anxiety, psychological benefits
                  like building self-confidence and increasing productivity, and
                  even the benefits to spiritual development such as increased
                  awareness and a deeper understanding of the self. With a focus on helping you monitor, understand, and properly process your thoughts and feelings, meditation crosses over into mindfulness practice. The Meditation Course explores this
                  relationship and how you can use both to balance your mind and
                  live a life of positivity. The course also explains the body’s
                  energy system, how meditation affects this and how this helps
                  the mind, body, and spirit.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="mindfulness" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/mindfulness.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Mindfulness</Link>
                </h3>
                <p>
                  Explore how mindfulness can improve your mental wellbeing,
                  review what you have learned along the way, and chart your
                  progress with self-assessment tools. By the end of the course, you'll be naturally practising mindfulness in your daily life, helping you to be present and not caught up in negative thoughts. You will be more aware and respond more skillfully to events and reduce and maintain low levels of stress, depression, and anxiety.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="chakras" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/chakras.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Chakras</Link>
                </h3>
                <p>
                  The chakras are a map of our inner world. They interact with
                  the Universal Life Force and animate the various aspects of
                  our lives. Maintaining balance in the chakras can help the
                  body, mind, and spirit in so many ways. Whether you suffer from chronic pain or your emotions feel out of balance, we see a vast range of symptoms when our chakras are out of alignment or blocked.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="breathing" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/breathing_techniques.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Breathing techniques"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Breathing techniques</Link>
                </h3>
                <p>
                  An educational foundation training on the healing and
                  stabilising power of the Breath. Learn the breathing
                  techniques to keep the mind and body healthy. Cultivate health and
                  happiness.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="massage" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/massage.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Massage</Link>
                </h3>
                <p>
                  This online Body Massage Diploma Course will provide students
                  with the skills required to become a qualified Therapist.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="acupuncture" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/acupuncture.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Breathing techniques"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Acupuncture</Link>
                </h3>
                <p>
                  These techniques, methods and practices date back to the
                  ancient Taoists and Asian Health Science Practitioners,
                  literally thousands of years ago. For centuries acupuncture practitioners have achieved remarkable feats of body-mind-spirit connection and have promoted health and longevity in individuals up to a century-old or more.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="ancestral" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block image">
                  <StaticImage
                    src="../../assets/images/cg-academy/ancestral.jpg"
                    width={380}
                    height={214}
                    placeholder="blurred"
                    alt="Breathing techniques"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>The Ancestral diploma</Link>
                </h3>
                <p>
                  We all have a responsibility to our ancestors - we are liable
                  for their actions if the resulting karma has not yet been
                  dissolved. As we stand at the front of the line in our
                  ancestry, the energies they embodied have been passed onto us
                  and are now expressing themselves through us and will continue
                  to do so if we don’t take steps to heal our ancestral lineage.
                  The Ancestral Diploma Course provides a deep understanding of
                  all areas of ancestral medicine, including performing and
                  writing rituals, helping troubled souls, and healing those in
                  your local area. The Ancestral diploma Course explains how and why it is important to work with your
                  ancestors and the benefits of doing so. Through this, you’ll
                  gain a general understanding of epigenetics and how it plays a
                  part in our ancestry and our living generations now and in the
                  future.
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="emotion" className="col-lg-4 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
              <Link to="/contact" state={{ page: "cg-academy-courses" }} className="d-block">
                  <VideoResponsivePlayer
                    url={data.site.siteMetadata.videosBaseUrl + '/courses/emotional.mp4'}
                    controls={false}
                    playing={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    height="100%"
                  />
                </Link>
              </div>
              <div className="courses-content">
                <h3>
                  <Link to="/contact" state={{ page: "cg-academy-courses" }}>Emotion & body course</Link>
                </h3>
                <p>
                  Greater clarity about the role some emotions play in creating
                  ill-health – and the powerful ways in which other emotional
                  states promote health (including the key role of oxytocin)
                </p>
                <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                  <li>
                    <i className="flaticon-agendas"></i> Coming Soon
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape6">
        <img src={shape} alt="about" />
      </div>
    </div>
  )
}

export default CoursesContent
